@charset "UTF-8";

/*
* Symbolset
* www.symbolset.com
* Copyright © 2012 Oak Studios LLC
*
* Upload this file to your web server
* and place this within your <head> tags.
* <link href="webfonts/ss-pika.css" rel="stylesheet" />
*/

@font-face {
  font-family: "SSPika";
  src: url('ss-pika.eot');
  src: url('ss-pika.eot?#iefix') format('embedded-opentype'),
    url('ss-pika.woff') format('woff'),
    url('ss-pika.ttf') format('truetype'),
    url('ss-pika.svg#SSPika') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* This triggers a redraw in IE to Fix IE8's :before content rendering. */
html:hover [class^="ss-"] {
  -ms-zoom: 1;
}

.ss-icon,
.ss-icon.ss-pika,
[class^="ss-"]:before,
[class*=" ss-"]:before,
[class^="ss-"].ss-pika:before,
[class*=" ss-"].ss-pika:before,
[class^="ss-"].right:after,
[class*=" ss-"].right:after,
[class^="ss-"].ss-pika.right:after,
[class*=" ss-"].ss-pika.right:after {
  font-family: "SSPika";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

[class^="ss-"].right:before,
[class*=" ss-"].right:before {
  display: none;
  content: '';
}

.ss-cursor:before,
.ss-cursor.right:after {
  content: ''
}

.ss-crosshair:before,
.ss-crosshair.right:after {
  content: '⌖'
}

.ss-search:before,
.ss-search.right:after {
  content: '🔎'
}

.ss-zoomin:before,
.ss-zoomin.right:after {
  content: ''
}

.ss-zoomout:before,
.ss-zoomout.right:after {
  content: ''
}

.ss-screenshot:before,
.ss-screenshot.right:after {
  content: ''
}

.ss-binoculars:before,
.ss-binoculars.right:after {
  content: ''
}

.ss-view:before,
.ss-view.right:after {
  content: '👀'
}

.ss-attach:before,
.ss-attach.right:after {
  content: '📎'
}

.ss-link:before,
.ss-link.right:after {
  content: '🔗'
}

.ss-move:before,
.ss-move.right:after {
  content: ''
}

.ss-write:before,
.ss-write.right:after {
  content: '✎'
}

.ss-writingdisabled:before,
.ss-writingdisabled.right:after {
  content: ''
}

.ss-erase:before,
.ss-erase.right:after {
  content: '✐'
}

.ss-compose:before,
.ss-compose.right:after {
  content: '📝'
}

.ss-draw:before,
.ss-draw.right:after {
  content: '✏'
}

.ss-lock:before,
.ss-lock.right:after {
  content: '🔒'
}

.ss-unlock:before,
.ss-unlock.right:after {
  content: '🔓'
}

.ss-key:before,
.ss-key.right:after {
  content: '🔑'
}

.ss-backspace:before,
.ss-backspace.right:after {
  content: '⌫'
}

.ss-ban:before,
.ss-ban.right:after {
  content: '🚫'
}

.ss-nosmoking:before,
.ss-nosmoking.right:after {
  content: '🚭'
}

.ss-trash:before,
.ss-trash.right:after {
  content: ''
}

.ss-target:before,
.ss-target.right:after {
  content: '◎'
}

.ss-new:before,
.ss-new.right:after {
  content: '🆕'
}

.ss-tag:before,
.ss-tag.right:after {
  content: ''
}

.ss-pricetag:before,
.ss-pricetag.right:after {
  content: ''
}

.ss-flowertag:before,
.ss-flowertag.right:after {
  content: ''
}

.ss-bookmark:before,
.ss-bookmark.right:after {
  content: '🔖'
}

.ss-flag:before,
.ss-flag.right:after {
  content: '⚑'
}

.ss-like:before,
.ss-like.right:after {
  content: '👍'
}

.ss-dislike:before,
.ss-dislike.right:after {
  content: '👎'
}

.ss-heart:before,
.ss-heart.right:after {
  content: '♥'
}

.ss-halfheart:before,
.ss-halfheart.right:after {
  content: ''
}

.ss-emptyheart:before,
.ss-emptyheart.right:after {
  content: '♡'
}

.ss-star:before,
.ss-star.right:after {
  content: '⋆'
}

.ss-halfstar:before,
.ss-halfstar.right:after {
  content: ''
}

.ss-medal:before,
.ss-medal.right:after {
  content: ''
}

.ss-ribbon:before,
.ss-ribbon.right:after {
  content: '🎀'
}

.ss-bullseye:before,
.ss-bullseye.right:after {
  content: '🎯'
}

.ss-sample:before,
.ss-sample.right:after {
  content: ''
}

.ss-crop:before,
.ss-crop.right:after {
  content: ''
}

.ss-layers:before,
.ss-layers.right:after {
  content: ''
}

.ss-gridlines:before,
.ss-gridlines.right:after {
  content: ''
}

.ss-pen:before,
.ss-pen.right:after {
  content: '✒'
}

.ss-feather:before,
.ss-feather.right:after {
  content: ''
}

.ss-rubbereraser:before,
.ss-rubbereraser.right:after {
  content: ''
}

.ss-paintroller:before,
.ss-paintroller.right:after {
  content: ''
}

.ss-rubberstamp:before,
.ss-rubberstamp.right:after {
  content: ''
}

.ss-checkclipboard:before,
.ss-checkclipboard.right:after {
  content: ''
}

.ss-linechartclipboard:before,
.ss-linechartclipboard.right:after {
  content: ''
}

.ss-clockwise:before,
.ss-clockwise.right:after {
  content: '⥁'
}

.ss-phone:before,
.ss-phone.right:after {
  content: '📞'
}

.ss-phonedisabled:before,
.ss-phonedisabled.right:after {
  content: ''
}

.ss-headset:before,
.ss-headset.right:after {
  content: ''
}

.ss-megaphone:before,
.ss-megaphone.right:after {
  content: '📢'
}

.ss-rss:before,
.ss-rss.right:after {
  content: ''
}

.ss-facetime:before,
.ss-facetime.right:after {
  content: ''
}

.ss-reply:before,
.ss-reply.right:after {
  content: '↩'
}

.ss-send:before,
.ss-send.right:after {
  content: ''
}

.ss-mail:before,
.ss-mail.right:after {
  content: '✉'
}

.ss-inbox:before,
.ss-inbox.right:after {
  content: '📥'
}

.ss-outbox:before,
.ss-outbox.right:after {
  content: '📤'
}

.ss-wifimail:before,
.ss-wifimail.right:after {
  content: ''
}

.ss-chat:before,
.ss-chat.right:after {
  content: '💬'
}

.ss-user:before,
.ss-user.right:after {
  content: '👤'
}

.ss-femaleuser:before,
.ss-femaleuser.right:after {
  content: '👧'
}

.ss-users:before,
.ss-users.right:after {
  content: '👥'
}

.ss-usergroup:before,
.ss-usergroup.right:after {
  content: ''
}

.ss-adduser:before,
.ss-adduser.right:after {
  content: ''
}

.ss-removeuser:before,
.ss-removeuser.right:after {
  content: ''
}

.ss-deleteuser:before,
.ss-deleteuser.right:after {
  content: ''
}

.ss-userprofile:before,
.ss-userprofile.right:after {
  content: ''
}

.ss-businessuser:before,
.ss-businessuser.right:after {
  content: ''
}

.ss-cart:before,
.ss-cart.right:after {
  content: ''
}

.ss-addcart:before,
.ss-addcart.right:after {
  content: ''
}

.ss-removecart:before,
.ss-removecart.right:after {
  content: ''
}

.ss-deletecart:before,
.ss-deletecart.right:after {
  content: ''
}

.ss-downloadcart:before,
.ss-downloadcart.right:after {
  content: ''
}

.ss-emptycart:before,
.ss-emptycart.right:after {
  content: ''
}

.ss-basket:before,
.ss-basket.right:after {
  content: ''
}

.ss-gift:before,
.ss-gift.right:after {
  content: '🎁'
}

.ss-apple:before,
.ss-apple.right:after {
  content: '🍏'
}

.ss-cashregister:before,
.ss-cashregister.right:after {
  content: ''
}

.ss-store:before,
.ss-store.right:after {
  content: '🏬'
}

.ss-searchbarcode:before,
.ss-searchbarcode.right:after {
  content: ''
}

.ss-notepad:before,
.ss-notepad.right:after {
  content: ''
}

.ss-creditcard:before,
.ss-creditcard.right:after {
  content: '💳'
}

.ss-safe:before,
.ss-safe.right:after {
  content: ''
}

.ss-digitalsafe:before,
.ss-digitalsafe.right:after {
  content: ''
}

.ss-atm:before,
.ss-atm.right:after {
  content: '🏧'
}

.ss-dispensecash:before,
.ss-dispensecash.right:after {
  content: ''
}

.ss-banknote:before,
.ss-banknote.right:after {
  content: '💵'
}

.ss-coins:before,
.ss-coins.right:after {
  content: ''
}

.ss-bankcheck:before,
.ss-bankcheck.right:after {
  content: ''
}

.ss-piggybank:before,
.ss-piggybank.right:after {
  content: ''
}

.ss-moneybag:before,
.ss-moneybag.right:after {
  content: '💰'
}

.ss-tip:before,
.ss-tip.right:after {
  content: ''
}

.ss-calculator:before,
.ss-calculator.right:after {
  content: ''
}

.ss-percent:before,
.ss-percent.right:after {
  content: '%'
}

.ss-bank:before,
.ss-bank.right:after {
  content: '🏦'
}

.ss-scales:before,
.ss-scales.right:after {
  content: '⚖'
}

.ss-piechart:before,
.ss-piechart.right:after {
  content: ''
}

.ss-piechartthirds:before,
.ss-piechartthirds.right:after {
  content: ''
}

.ss-barchart:before,
.ss-barchart.right:after {
  content: '📊'
}

.ss-upwardsbarchart:before,
.ss-upwardsbarchart.right:after {
  content: ''
}

.ss-downwardsbarchart:before,
.ss-downwardsbarchart.right:after {
  content: ''
}

.ss-horizontalbarchart:before,
.ss-horizontalbarchart.right:after {
  content: ''
}

.ss-analytics:before,
.ss-analytics.right:after {
  content: ''
}

.ss-upwardslinechart:before,
.ss-upwardslinechart.right:after {
  content: '📈'
}

.ss-downwardslinechart:before,
.ss-downwardslinechart.right:after {
  content: '📉'
}

.ss-linecharts:before,
.ss-linecharts.right:after {
  content: ''
}

.ss-scatterchart:before,
.ss-scatterchart.right:after {
  content: ''
}

.ss-stock:before,
.ss-stock.right:after {
  content: ''
}

.ss-form:before,
.ss-form.right:after {
  content: ''
}

.ss-box:before,
.ss-box.right:after {
  content: '📦'
}

.ss-crate:before,
.ss-crate.right:after {
  content: ''
}

.ss-deliveryvan:before,
.ss-deliveryvan.right:after {
  content: ''
}

.ss-deliverytruck:before,
.ss-deliverytruck.right:after {
  content: ''
}

.ss-forklift:before,
.ss-forklift.right:after {
  content: ''
}

.ss-cargoship:before,
.ss-cargoship.right:after {
  content: ''
}

.ss-hook:before,
.ss-hook.right:after {
  content: ''
}

.ss-weight:before,
.ss-weight.right:after {
  content: ''
}

.ss-containers:before,
.ss-containers.right:after {
  content: ''
}

.ss-fragile:before,
.ss-fragile.right:after {
  content: ''
}

.ss-thissideup:before,
.ss-thissideup.right:after {
  content: ''
}

.ss-home:before,
.ss-home.right:after {
  content: '⌂'
}

.ss-building:before,
.ss-building.right:after {
  content: '🏢'
}

.ss-lodging:before,
.ss-lodging.right:after {
  content: '🏨'
}

.ss-earth:before,
.ss-earth.right:after {
  content: '🌎'
}

.ss-globe:before,
.ss-globe.right:after {
  content: '🌐'
}

.ss-garage:before,
.ss-garage.right:after {
  content: ''
}

.ss-warehouse:before,
.ss-warehouse.right:after {
  content: ''
}

.ss-floorplan:before,
.ss-floorplan.right:after {
  content: ''
}

.ss-address:before,
.ss-address.right:after {
  content: ''
}

.ss-searchhouse:before,
.ss-searchhouse.right:after {
  content: ''
}

.ss-housesale:before,
.ss-housesale.right:after {
  content: ''
}

.ss-navigate:before,
.ss-navigate.right:after {
  content: ''
}

.ss-compass:before,
.ss-compass.right:after {
  content: ''
}

.ss-signpost:before,
.ss-signpost.right:after {
  content: ''
}

.ss-map:before,
.ss-map.right:after {
  content: ''
}

.ss-gps:before,
.ss-gps.right:after {
  content: ''
}

.ss-compassnavigate:before,
.ss-compassnavigate.right:after {
  content: ''
}

.ss-compassarrow:before,
.ss-compassarrow.right:after {
  content: ''
}

.ss-location:before,
.ss-location.right:after {
  content: ''
}

.ss-pin:before,
.ss-pin.right:after {
  content: '📍'
}

.ss-pushpin:before,
.ss-pushpin.right:after {
  content: '📌'
}

.ss-maplocation:before,
.ss-maplocation.right:after {
  content: ''
}

.ss-globelocation:before,
.ss-globelocation.right:after {
  content: ''
}

.ss-puzzle:before,
.ss-puzzle.right:after {
  content: ''
}

.ss-floppydisk:before,
.ss-floppydisk.right:after {
  content: '💾'
}

.ss-firewall:before,
.ss-firewall.right:after {
  content: ''
}

.ss-database:before,
.ss-database.right:after {
  content: ''
}

.ss-hdd:before,
.ss-hdd.right:after {
  content: ''
}

.ss-music:before,
.ss-music.right:after {
  content: '♫'
}

.ss-eighthnote:before,
.ss-eighthnote.right:after {
  content: '♪'
}

.ss-mic:before,
.ss-mic.right:after {
  content: '🎤'
}

.ss-volume:before,
.ss-volume.right:after {
  content: '🔈'
}

.ss-lowvolume:before,
.ss-lowvolume.right:after {
  content: '🔉'
}

.ss-highvolume:before,
.ss-highvolume.right:after {
  content: '🔊'
}

.ss-radio:before,
.ss-radio.right:after {
  content: '📻'
}

.ss-stereo:before,
.ss-stereo.right:after {
  content: ''
}

.ss-airplay:before,
.ss-airplay.right:after {
  content: ''
}

.ss-vinylrecord:before,
.ss-vinylrecord.right:after {
  content: ''
}

.ss-disc:before,
.ss-disc.right:after {
  content: '💿'
}

.ss-camera:before,
.ss-camera.right:after {
  content: '📷'
}

.ss-picture:before,
.ss-picture.right:after {
  content: '🌄'
}

.ss-video:before,
.ss-video.right:after {
  content: '📹'
}

.ss-playvideo:before,
.ss-playvideo.right:after {
  content: ''
}

.ss-play:before,
.ss-play.right:after {
  content: '▶'
}

.ss-pause:before,
.ss-pause.right:after {
  content: ''
}

.ss-stop:before,
.ss-stop.right:after {
  content: '■'
}

.ss-record:before,
.ss-record.right:after {
  content: '●'
}

.ss-rewind:before,
.ss-rewind.right:after {
  content: '⏪'
}

.ss-fastforward:before,
.ss-fastforward.right:after {
  content: '⏩'
}

.ss-skipback:before,
.ss-skipback.right:after {
  content: '⏮'
}

.ss-skipforward:before,
.ss-skipforward.right:after {
  content: '⏭'
}

.ss-eject:before,
.ss-eject.right:after {
  content: '⏏'
}

.ss-repeat:before,
.ss-repeat.right:after {
  content: '🔁'
}

.ss-replay:before,
.ss-replay.right:after {
  content: '↺'
}

.ss-shuffle:before,
.ss-shuffle.right:after {
  content: '🔀'
}

.ss-index:before,
.ss-index.right:after {
  content: ''
}

.ss-page:before,
.ss-page.right:after {
  content: ''
}

.ss-book:before,
.ss-book.right:after {
  content: '📕'
}

.ss-books:before,
.ss-books.right:after {
  content: '📚'
}

.ss-openbook:before,
.ss-openbook.right:after {
  content: '📖'
}

.ss-heartbook:before,
.ss-heartbook.right:after {
  content: ''
}

.ss-downloadbook:before,
.ss-downloadbook.right:after {
  content: ''
}

.ss-musicopenbook:before,
.ss-musicopenbook.right:after {
  content: ''
}

.ss-searchbook:before,
.ss-searchbook.right:after {
  content: ''
}

.ss-notebook:before,
.ss-notebook.right:after {
  content: '📓'
}

.ss-newspaper:before,
.ss-newspaper.right:after {
  content: '📰'
}

.ss-notice:before,
.ss-notice.right:after {
  content: ''
}

.ss-rows:before,
.ss-rows.right:after {
  content: ''
}

.ss-columns:before,
.ss-columns.right:after {
  content: ''
}

.ss-thumbnails:before,
.ss-thumbnails.right:after {
  content: ''
}

.ss-pagecurl:before,
.ss-pagecurl.right:after {
  content: ''
}

.ss-desktop:before,
.ss-desktop.right:after {
  content: '💻'
}

.ss-laptop:before,
.ss-laptop.right:after {
  content: ''
}

.ss-tablet:before,
.ss-tablet.right:after {
  content: ''
}

.ss-smartphone:before,
.ss-smartphone.right:after {
  content: ''
}

.ss-cell:before,
.ss-cell.right:after {
  content: '📱'
}

.ss-walkytalky:before,
.ss-walkytalky.right:after {
  content: ''
}

.ss-ereader:before,
.ss-ereader.right:after {
  content: ''
}

.ss-ebook:before,
.ss-ebook.right:after {
  content: ''
}

.ss-wifibook:before,
.ss-wifibook.right:after {
  content: ''
}

.ss-wifiopenbook:before,
.ss-wifiopenbook.right:after {
  content: ''
}

.ss-battery:before,
.ss-battery.right:after {
  content: '🔋'
}

.ss-highbattery:before,
.ss-highbattery.right:after {
  content: ''
}

.ss-mediumbattery:before,
.ss-mediumbattery.right:after {
  content: ''
}

.ss-lowbattery:before,
.ss-lowbattery.right:after {
  content: ''
}

.ss-emptybattery:before,
.ss-emptybattery.right:after {
  content: ''
}

.ss-chargingbattery:before,
.ss-chargingbattery.right:after {
  content: ''
}

.ss-heartmobile:before,
.ss-heartmobile.right:after {
  content: ''
}

.ss-phonemobile:before,
.ss-phonemobile.right:after {
  content: ''
}

.ss-lockmobile:before,
.ss-lockmobile.right:after {
  content: ''
}

.ss-textmobile:before,
.ss-textmobile.right:after {
  content: ''
}

.ss-dollarmobile:before,
.ss-dollarmobile.right:after {
  content: ''
}

.ss-euromobile:before,
.ss-euromobile.right:after {
  content: ''
}

.ss-rssmobile:before,
.ss-rssmobile.right:after {
  content: ''
}

.ss-navigationmobile:before,
.ss-navigationmobile.right:after {
  content: ''
}

.ss-batterymobile:before,
.ss-batterymobile.right:after {
  content: ''
}

.ss-powermobile:before,
.ss-powermobile.right:after {
  content: ''
}

.ss-browseonline:before,
.ss-browseonline.right:after {
  content: ''
}

.ss-shoponline:before,
.ss-shoponline.right:after {
  content: ''
}

.ss-bankonline:before,
.ss-bankonline.right:after {
  content: ''
}

.ss-realtyonline:before,
.ss-realtyonline.right:after {
  content: ''
}

.ss-readonline:before,
.ss-readonline.right:after {
  content: ''
}

.ss-weatheronline:before,
.ss-weatheronline.right:after {
  content: ''
}

.ss-lightbulb:before,
.ss-lightbulb.right:after {
  content: '💡'
}

.ss-lightbulbon:before,
.ss-lightbulbon.right:after {
  content: ''
}

.ss-cfl:before,
.ss-cfl.right:after {
  content: ''
}

.ss-hairdryer:before,
.ss-hairdryer.right:after {
  content: ''
}

.ss-picnictable:before,
.ss-picnictable.right:after {
  content: ''
}

.ss-flame:before,
.ss-flame.right:after {
  content: ''
}

.ss-campfire:before,
.ss-campfire.right:after {
  content: '🔥'
}

.ss-bonfire:before,
.ss-bonfire.right:after {
  content: ''
}

.ss-balloon:before,
.ss-balloon.right:after {
  content: '🎈'
}

.ss-christmastree:before,
.ss-christmastree.right:after {
  content: '🎄'
}

.ss-sweep:before,
.ss-sweep.right:after {
  content: ''
}

.ss-chess:before,
.ss-chess.right:after {
  content: '♞'
}

.ss-onedie:before,
.ss-onedie.right:after {
  content: '⚀'
}

.ss-twodie:before,
.ss-twodie.right:after {
  content: '⚁'
}

.ss-threedie:before,
.ss-threedie.right:after {
  content: '⚂'
}

.ss-fourdie:before,
.ss-fourdie.right:after {
  content: '⚃'
}

.ss-fivedie:before,
.ss-fivedie.right:after {
  content: '⚄'
}

.ss-sixdie:before,
.ss-sixdie.right:after {
  content: '⚅'
}

.ss-downloadcloud:before,
.ss-downloadcloud.right:after {
  content: ''
}

.ss-download:before,
.ss-download.right:after {
  content: ''
}

.ss-downloadbox:before,
.ss-downloadbox.right:after {
  content: ''
}

.ss-downloadcrate:before,
.ss-downloadcrate.right:after {
  content: ''
}

.ss-uploadcloud:before,
.ss-uploadcloud.right:after {
  content: ''
}

.ss-upload:before,
.ss-upload.right:after {
  content: ''
}

.ss-uploadbox:before,
.ss-uploadbox.right:after {
  content: ''
}

.ss-uploadcrate:before,
.ss-uploadcrate.right:after {
  content: ''
}

.ss-fork:before,
.ss-fork.right:after {
  content: ''
}

.ss-merge:before,
.ss-merge.right:after {
  content: ''
}

.ss-refresh:before,
.ss-refresh.right:after {
  content: '↻'
}

.ss-sync:before,
.ss-sync.right:after {
  content: ''
}

.ss-loading:before,
.ss-loading.right:after {
  content: ''
}

.ss-wifi:before,
.ss-wifi.right:after {
  content: ''
}

.ss-connection:before,
.ss-connection.right:after {
  content: ''
}

.ss-reload:before,
.ss-reload.right:after {
  content: ''
}

.ss-file:before,
.ss-file.right:after {
  content: '📄'
}

.ss-addfile:before,
.ss-addfile.right:after {
  content: ''
}

.ss-removefile:before,
.ss-removefile.right:after {
  content: ''
}

.ss-deletefile:before,
.ss-deletefile.right:after {
  content: ''
}

.ss-downloadfile:before,
.ss-downloadfile.right:after {
  content: ''
}

.ss-uploadfile:before,
.ss-uploadfile.right:after {
  content: ''
}

.ss-importfile:before,
.ss-importfile.right:after {
  content: ''
}

.ss-exportfile:before,
.ss-exportfile.right:after {
  content: ''
}

.ss-settingsfile:before,
.ss-settingsfile.right:after {
  content: ''
}

.ss-lockfile:before,
.ss-lockfile.right:after {
  content: ''
}

.ss-userfile:before,
.ss-userfile.right:after {
  content: ''
}

.ss-picturefile:before,
.ss-picturefile.right:after {
  content: ''
}

.ss-textfile:before,
.ss-textfile.right:after {
  content: ''
}

.ss-exe:before,
.ss-exe.right:after {
  content: ''
}

.ss-zip:before,
.ss-zip.right:after {
  content: ''
}

.ss-doc:before,
.ss-doc.right:after {
  content: ''
}

.ss-ppt:before,
.ss-ppt.right:after {
  content: ''
}

.ss-pdf:before,
.ss-pdf.right:after {
  content: ''
}

.ss-jpg:before,
.ss-jpg.right:after {
  content: ''
}

.ss-png:before,
.ss-png.right:after {
  content: ''
}

.ss-folder:before,
.ss-folder.right:after {
  content: '📁'
}

.ss-openfolder:before,
.ss-openfolder.right:after {
  content: '📂'
}

.ss-downloadfolder:before,
.ss-downloadfolder.right:after {
  content: ''
}

.ss-uploadfolder:before,
.ss-uploadfolder.right:after {
  content: ''
}

.ss-cloudfolder:before,
.ss-cloudfolder.right:after {
  content: ''
}

.ss-lockfolder:before,
.ss-lockfolder.right:after {
  content: ''
}

.ss-securefolder:before,
.ss-securefolder.right:after {
  content: ''
}

.ss-picturefolder:before,
.ss-picturefolder.right:after {
  content: ''
}

.ss-moviefolder:before,
.ss-moviefolder.right:after {
  content: ''
}

.ss-quote:before,
.ss-quote.right:after {
  content: '“'
}

.ss-text:before,
.ss-text.right:after {
  content: ''
}

.ss-font:before,
.ss-font.right:after {
  content: ''
}

.ss-highlight:before,
.ss-highlight.right:after {
  content: ''
}

.ss-print:before,
.ss-print.right:after {
  content: '⎙'
}

.ss-fax:before,
.ss-fax.right:after {
  content: '📠'
}

.ss-list:before,
.ss-list.right:after {
  content: ''
}

.ss-layout:before,
.ss-layout.right:after {
  content: ''
}

.ss-action:before,
.ss-action.right:after {
  content: ''
}

.ss-redirect:before,
.ss-redirect.right:after {
  content: '↪'
}

.ss-expand:before,
.ss-expand.right:after {
  content: '⤢'
}

.ss-contract:before,
.ss-contract.right:after {
  content: ''
}

.ss-help:before,
.ss-help.right:after {
  content: '❓'
}

.ss-info:before,
.ss-info.right:after {
  content: 'ℹ'
}

.ss-alert:before,
.ss-alert.right:after {
  content: '⚠'
}

.ss-caution:before,
.ss-caution.right:after {
  content: '⛔'
}

.ss-logout:before,
.ss-logout.right:after {
  content: ''
}

.ss-login:before,
.ss-login.right:after {
  content: ''
}

.ss-scaleup:before,
.ss-scaleup.right:after {
  content: ''
}

.ss-scaledown:before,
.ss-scaledown.right:after {
  content: ''
}

.ss-plus:before,
.ss-plus.right:after {
  content: '+'
}

.ss-hyphen:before,
.ss-hyphen.right:after {
  content: '-'
}

.ss-check:before,
.ss-check.right:after {
  content: '✓'
}

.ss-delete:before,
.ss-delete.right:after {
  content: '␡'
}

.ss-bearface:before,
.ss-bearface.right:after {
  content: '🐻'
}

.ss-bird:before,
.ss-bird.right:after {
  content: '🐦'
}

.ss-fishes:before,
.ss-fishes.right:after {
  content: ''
}

.ss-tropicalfish:before,
.ss-tropicalfish.right:after {
  content: ''
}

.ss-tree:before,
.ss-tree.right:after {
  content: '🌳'
}

.ss-evergreen:before,
.ss-evergreen.right:after {
  content: '🌲'
}

.ss-palmtree:before,
.ss-palmtree.right:after {
  content: '🌴'
}

.ss-leaf:before,
.ss-leaf.right:after {
  content: '🍂'
}

.ss-seedling:before,
.ss-seedling.right:after {
  content: '🌱'
}

.ss-grass:before,
.ss-grass.right:after {
  content: ''
}

.ss-settings:before,
.ss-settings.right:after {
  content: '⚙'
}

.ss-dashboard:before,
.ss-dashboard.right:after {
  content: ''
}

.ss-dial:before,
.ss-dial.right:after {
  content: ''
}

.ss-notifications:before,
.ss-notifications.right:after {
  content: '🔔'
}

.ss-notificationsdisabled:before,
.ss-notificationsdisabled.right:after {
  content: '🔕'
}

.ss-flash:before,
.ss-flash.right:after {
  content: '⌁'
}

.ss-wrench:before,
.ss-wrench.right:after {
  content: '🔧'
}

.ss-tapemeasure:before,
.ss-tapemeasure.right:after {
  content: ''
}

.ss-clock:before,
.ss-clock.right:after {
  content: '⏲'
}

.ss-watch:before,
.ss-watch.right:after {
  content: '⌚'
}

.ss-stopwatch:before,
.ss-stopwatch.right:after {
  content: '⏱'
}

.ss-alarmclock:before,
.ss-alarmclock.right:after {
  content: '⏰'
}

.ss-calendar:before,
.ss-calendar.right:after {
  content: '📅'
}

.ss-addcalendar:before,
.ss-addcalendar.right:after {
  content: ''
}

.ss-removecalendar:before,
.ss-removecalendar.right:after {
  content: ''
}

.ss-checkcalendar:before,
.ss-checkcalendar.right:after {
  content: ''
}

.ss-deletecalendar:before,
.ss-deletecalendar.right:after {
  content: ''
}

.ss-sausage:before,
.ss-sausage.right:after {
  content: ''
}

.ss-burger:before,
.ss-burger.right:after {
  content: '🍔'
}

.ss-pizza:before,
.ss-pizza.right:after {
  content: '🍕'
}

.ss-fish:before,
.ss-fish.right:after {
  content: '🐟'
}

.ss-shrimp:before,
.ss-shrimp.right:after {
  content: '🍤'
}

.ss-turkey:before,
.ss-turkey.right:after {
  content: ''
}

.ss-steak:before,
.ss-steak.right:after {
  content: ''
}

.ss-sidedish:before,
.ss-sidedish.right:after {
  content: '🍚'
}

.ss-noodles:before,
.ss-noodles.right:after {
  content: '🍜'
}

.ss-spaghetti:before,
.ss-spaghetti.right:after {
  content: '🍝'
}

.ss-corn:before,
.ss-corn.right:after {
  content: ''
}

.ss-carrot:before,
.ss-carrot.right:after {
  content: ''
}

.ss-icecream:before,
.ss-icecream.right:after {
  content: '🍨'
}

.ss-mug:before,
.ss-mug.right:after {
  content: '☕'
}

.ss-beer:before,
.ss-beer.right:after {
  content: '🍺'
}

.ss-bottle:before,
.ss-bottle.right:after {
  content: ''
}

.ss-wineglass:before,
.ss-wineglass.right:after {
  content: '🍷'
}

.ss-cocktail:before,
.ss-cocktail.right:after {
  content: '🍸'
}

.ss-tea:before,
.ss-tea.right:after {
  content: '🍵'
}

.ss-teapot:before,
.ss-teapot.right:after {
  content: ''
}

.ss-waterbottle:before,
.ss-waterbottle.right:after {
  content: ''
}

.ss-wineglasssparkle:before,
.ss-wineglasssparkle.right:after {
  content: ''
}

.ss-salt:before,
.ss-salt.right:after {
  content: ''
}

.ss-pepper:before,
.ss-pepper.right:after {
  content: ''
}

.ss-oliveoil:before,
.ss-oliveoil.right:after {
  content: ''
}

.ss-hotsauce:before,
.ss-hotsauce.right:after {
  content: ''
}

.ss-coal:before,
.ss-coal.right:after {
  content: ''
}

.ss-oven:before,
.ss-oven.right:after {
  content: ''
}

.ss-stove:before,
.ss-stove.right:after {
  content: ''
}

.ss-cook:before,
.ss-cook.right:after {
  content: '🍳'
}

.ss-bbq:before,
.ss-bbq.right:after {
  content: ''
}

.ss-utensils:before,
.ss-utensils.right:after {
  content: '🍴'
}

.ss-spoonfork:before,
.ss-spoonfork.right:after {
  content: ''
}

.ss-knife:before,
.ss-knife.right:after {
  content: '🔪'
}

.ss-cookingutensils:before,
.ss-cookingutensils.right:after {
  content: ''
}

.ss-measuringcup:before,
.ss-measuringcup.right:after {
  content: ''
}

.ss-colander:before,
.ss-colander.right:after {
  content: ''
}

.ss-scale:before,
.ss-scale.right:after {
  content: ''
}

.ss-eggtimer:before,
.ss-eggtimer.right:after {
  content: ''
}

.ss-platter:before,
.ss-platter.right:after {
  content: ''
}

.ss-apron:before,
.ss-apron.right:after {
  content: ''
}

.ss-bbqapron:before,
.ss-bbqapron.right:after {
  content: ''
}

.ss-chef:before,
.ss-chef.right:after {
  content: ''
}

.ss-handbag:before,
.ss-handbag.right:after {
  content: '👜'
}

.ss-briefcase:before,
.ss-briefcase.right:after {
  content: '💼'
}

.ss-hanger:before,
.ss-hanger.right:after {
  content: ''
}

.ss-weathervane:before,
.ss-weathervane.right:after {
  content: ''
}

.ss-thermometer:before,
.ss-thermometer.right:after {
  content: ''
}

.ss-weather:before,
.ss-weather.right:after {
  content: ''
}

.ss-cloud:before,
.ss-cloud.right:after {
  content: '☁'
}

.ss-droplet:before,
.ss-droplet.right:after {
  content: '💧'
}

.ss-sun:before,
.ss-sun.right:after {
  content: '☀'
}

.ss-partlycloudy:before,
.ss-partlycloudy.right:after {
  content: '⛅'
}

.ss-rain:before,
.ss-rain.right:after {
  content: '☔'
}

.ss-thunderstorm:before,
.ss-thunderstorm.right:after {
  content: '⛈'
}

.ss-umbrella:before,
.ss-umbrella.right:after {
  content: '☂'
}

.ss-rainbow:before,
.ss-rainbow.right:after {
  content: '🌈'
}

.ss-fog:before,
.ss-fog.right:after {
  content: ''
}

.ss-wind:before,
.ss-wind.right:after {
  content: ''
}

.ss-tornado:before,
.ss-tornado.right:after {
  content: ''
}

.ss-snowflake:before,
.ss-snowflake.right:after {
  content: '❄'
}

.ss-fan:before,
.ss-fan.right:after {
  content: ''
}

.ss-solarpanel:before,
.ss-solarpanel.right:after {
  content: ''
}

.ss-plug:before,
.ss-plug.right:after {
  content: '🔌'
}

.ss-outlet:before,
.ss-outlet.right:after {
  content: ''
}

.ss-car:before,
.ss-car.right:after {
  content: '🚘'
}

.ss-taxi:before,
.ss-taxi.right:after {
  content: '🚖'
}

.ss-locomotive:before,
.ss-locomotive.right:after {
  content: '🚂'
}

.ss-train:before,
.ss-train.right:after {
  content: '🚆'
}

.ss-traintunnel:before,
.ss-traintunnel.right:after {
  content: '🚇'
}

.ss-bus:before,
.ss-bus.right:after {
  content: '🚍'
}

.ss-truck:before,
.ss-truck.right:after {
  content: '🚚'
}

.ss-caravan:before,
.ss-caravan.right:after {
  content: '🚐'
}

.ss-tractor:before,
.ss-tractor.right:after {
  content: '🚜'
}

.ss-tunnel:before,
.ss-tunnel.right:after {
  content: ''
}

.ss-plane:before,
.ss-plane.right:after {
  content: '✈'
}

.ss-arrival:before,
.ss-arrival.right:after {
  content: ''
}

.ss-departure:before,
.ss-departure.right:after {
  content: ''
}

.ss-helicopter:before,
.ss-helicopter.right:after {
  content: '🚁'
}

.ss-bike:before,
.ss-bike.right:after {
  content: '🚲'
}

.ss-motorcycle:before,
.ss-motorcycle.right:after {
  content: ''
}

.ss-boat:before,
.ss-boat.right:after {
  content: '🚢'
}

.ss-sailboat:before,
.ss-sailboat.right:after {
  content: '⛵'
}

.ss-schooner:before,
.ss-schooner.right:after {
  content: ''
}

.ss-skylift:before,
.ss-skylift.right:after {
  content: '🚡'
}

.ss-rocket:before,
.ss-rocket.right:after {
  content: '🚀'
}

.ss-steeringwheel:before,
.ss-steeringwheel.right:after {
  content: ''
}

.ss-trafficcamera:before,
.ss-trafficcamera.right:after {
  content: ''
}

.ss-fuel:before,
.ss-fuel.right:after {
  content: '⛽'
}

.ss-jerrycan:before,
.ss-jerrycan.right:after {
  content: ''
}

.ss-passport:before,
.ss-passport.right:after {
  content: ''
}

.ss-trafficlight:before,
.ss-trafficlight.right:after {
  content: '🚦'
}

.ss-highway:before,
.ss-highway.right:after {
  content: ''
}

.ss-road:before,
.ss-road.right:after {
  content: ''
}

.ss-intersection:before,
.ss-intersection.right:after {
  content: ''
}

.ss-wheelchair:before,
.ss-wheelchair.right:after {
  content: '♿'
}

.ss-elevator:before,
.ss-elevator.right:after {
  content: ''
}

.ss-golf:before,
.ss-golf.right:after {
  content: ''
}

.ss-hockey:before,
.ss-hockey.right:after {
  content: ''
}

.ss-iceskate:before,
.ss-iceskate.right:after {
  content: '⛸'
}

.ss-billiards:before,
.ss-billiards.right:after {
  content: '🎱'
}

.ss-baseballglove:before,
.ss-baseballglove.right:after {
  content: ''
}

.ss-tennis:before,
.ss-tennis.right:after {
  content: '🎾'
}

.ss-tabletennis:before,
.ss-tabletennis.right:after {
  content: ''
}

.ss-badminton:before,
.ss-badminton.right:after {
  content: ''
}

.ss-boxing:before,
.ss-boxing.right:after {
  content: ''
}

.ss-bowling:before,
.ss-bowling.right:after {
  content: ''
}

.ss-football:before,
.ss-football.right:after {
  content: '🏈'
}

.ss-soccer:before,
.ss-soccer.right:after {
  content: '⚽'
}

.ss-hiker:before,
.ss-hiker.right:after {
  content: ''
}

.ss-pool:before,
.ss-pool.right:after {
  content: ''
}

.ss-shower:before,
.ss-shower.right:after {
  content: '🚿'
}

.ss-exercise:before,
.ss-exercise.right:after {
  content: ''
}

.ss-exercisebike:before,
.ss-exercisebike.right:after {
  content: ''
}

.ss-dumbbell:before,
.ss-dumbbell.right:after {
  content: ''
}

.ss-jumprope:before,
.ss-jumprope.right:after {
  content: ''
}

.ss-yoga:before,
.ss-yoga.right:after {
  content: ''
}

.ss-suitcase:before,
.ss-suitcase.right:after {
  content: ''
}

.ss-luggage:before,
.ss-luggage.right:after {
  content: ''
}

.ss-donotdisturb:before,
.ss-donotdisturb.right:after {
  content: ''
}

.ss-sunscreen:before,
.ss-sunscreen.right:after {
  content: ''
}

.ss-callbell:before,
.ss-callbell.right:after {
  content: ''
}

.ss-hospital:before,
.ss-hospital.right:after {
  content: '⛨'
}

.ss-medicalcross:before,
.ss-medicalcross.right:after {
  content: ''
}

.ss-ambulance:before,
.ss-ambulance.right:after {
  content: '🚑'
}

.ss-bandage:before,
.ss-bandage.right:after {
  content: ''
}

.ss-medicalthermometer:before,
.ss-medicalthermometer.right:after {
  content: ''
}

.ss-stethoscope:before,
.ss-stethoscope.right:after {
  content: ''
}

.ss-syringe:before,
.ss-syringe.right:after {
  content: '💉'
}

.ss-pill:before,
.ss-pill.right:after {
  content: '💊'
}

.ss-pillbottle:before,
.ss-pillbottle.right:after {
  content: ''
}

.ss-supplements:before,
.ss-supplements.right:after {
  content: ''
}

.ss-bathroomscale:before,
.ss-bathroomscale.right:after {
  content: ''
}

.ss-dna:before,
.ss-dna.right:after {
  content: ''
}

.ss-anatomicalheart:before,
.ss-anatomicalheart.right:after {
  content: ''
}

.ss-checkheart:before,
.ss-checkheart.right:after {
  content: ''
}

.ss-eyesurgery:before,
.ss-eyesurgery.right:after {
  content: ''
}

.ss-brokenbone:before,
.ss-brokenbone.right:after {
  content: ''
}

.ss-up:before,
.ss-up.right:after {
  content: '⬆'
}

.ss-upright:before,
.ss-upright.right:after {
  content: '⬈'
}

.ss-right:before,
.ss-right.right:after {
  content: '➡'
}

.ss-downright:before,
.ss-downright.right:after {
  content: '⬊'
}

.ss-down:before,
.ss-down.right:after {
  content: '⬇'
}

.ss-downleft:before,
.ss-downleft.right:after {
  content: '⬋'
}

.ss-left:before,
.ss-left.right:after {
  content: '⬅'
}

.ss-upleft:before,
.ss-upleft.right:after {
  content: '⬉'
}

.ss-navigateup:before,
.ss-navigateup.right:after {
  content: ''
}

.ss-navigateright:before,
.ss-navigateright.right:after {
  content: '▻'
}

.ss-navigatedown:before,
.ss-navigatedown.right:after {
  content: ''
}

.ss-navigateleft:before,
.ss-navigateleft.right:after {
  content: '◅'
}

.ss-directup:before,
.ss-directup.right:after {
  content: '▴'
}

.ss-directright:before,
.ss-directright.right:after {
  content: '▹'
}

.ss-dropdown:before,
.ss-dropdown.right:after {
  content: '▾'
}

.ss-directleft:before,
.ss-directleft.right:after {
  content: '◃'
}

.ss-leftright:before,
.ss-leftright.right:after {
  content: '⬌'
}

.ss-rightward:before,
.ss-rightward.right:after {
  content: ''
}

.ss-leftward:before,
.ss-leftward.right:after {
  content: ''
}

.ss-previouspage:before,
.ss-previouspage.right:after {
  content: '⎗'
}

.ss-nextpage:before,
.ss-nextpage.right:after {
  content: '⎘'
}

.ss-retweet:before,
.ss-retweet.right:after {
  content: ''
}

.ss-share:before,
.ss-share.right:after {
  content: ''
}