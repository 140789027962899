@charset "UTF-8";

/*
* Symbolset
* www.symbolset.com
* Copyright © 2012 Oak Studios LLC
*
* Upload this file to your web server
* and place this within your <head> tags.
* <link href="webfonts/ss-social.css" rel="stylesheet" />
*/

@font-face {
  font-family: "SSSocial";
  src: url('ss-social-circle.eot');
  src: url('ss-social-circle.eot?#iefix') format('embedded-opentype'),
       url('ss-social-circle.woff') format('woff'),
       url('ss-social-circle.ttf') format('truetype'),
       url('ss-social-circle.svg#SSSocialCircle') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SSSocial";
  src: url('ss-social-regular.eot');
  src: url('ss-social-regular.eot?#iefix') format('embedded-opentype'),
       url('ss-social-regular.woff') format('woff'),
       url('ss-social-regular.ttf') format('truetype'),
       url('ss-social-regular.svg#SSSocialRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* This triggers a redraw in IE to Fix IE8's :before content rendering. */
html:hover [class^="ss-"]{-ms-zoom: 1;}

.ss-icon, .ss-icon.ss-social, .ss-icon.ss-social-circle,
[class^="ss-"]:before, [class*=" ss-"]:before,
[class^="ss-"][class*=" ss-social"]:before, [class*=" ss-"][class*=" ss-social"]:before,
[class^="ss-"].right:after, [class*=" ss-"].right:after,
[class^="ss-"][class*=" ss-social"].right:after, [class*=" ss-"][class*=" ss-social"].right:after{
  font-family: "SSSocial";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  /*-webkit-font-feature-settings: "liga"; Currently broken in Chrome >= v22. Falls back to text-rendering. Safari is unaffected. */
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.ss-icon.ss-social-circle,
[class^="ss-"].ss-social-circle:before, [class*=" ss-"].ss-social-circle:before,
[class^="ss-"].ss-social-circle.right:after, [class*=" ss-"].ss-social-circle.right:after{
  font-weight: bold;
}

[class^="ss-"].right:before,
[class*=" ss-"].right:before{display:none;content:'';}

.ss-facebook:before,.ss-facebook.right:after{content:''}.ss-twitter:before,.ss-twitter.right:after{content:''}.ss-linkedin:before,.ss-linkedin.right:after{content:''}.ss-googleplus:before,.ss-googleplus.right:after{content:''}.ss-tumblr:before,.ss-tumblr.right:after{content:''}.ss-wordpress:before,.ss-wordpress.right:after{content:''}.ss-blogger:before,.ss-blogger.right:after{content:''}.ss-posterous:before,.ss-posterous.right:after{content:''}.ss-youtube:before,.ss-youtube.right:after{content:''}.ss-vimeo:before,.ss-vimeo.right:after{content:''}.ss-flickr:before,.ss-flickr.right:after{content:''}.ss-instagram:before,.ss-instagram.right:after{content:''}.ss-pinterest:before,.ss-pinterest.right:after{content:''}.ss-svpply:before,.ss-svpply.right:after{content:''}.ss-readmill:before,.ss-readmill.right:after{content:''}.ss-dropbox:before,.ss-dropbox.right:after{content:''}.ss-dribbble:before,.ss-dribbble.right:after{content:''}.ss-behance:before,.ss-behance.right:after{content:''}.ss-github:before,.ss-github.right:after{content:''}.ss-octocat:before,.ss-octocat.right:after{content:''}.ss-stackoverflow:before,.ss-stackoverflow.right:after{content:''}.ss-paypal:before,.ss-paypal.right:after{content:''}.ss-kickstarter:before,.ss-kickstarter.right:after{content:''}.ss-foursquare:before,.ss-foursquare.right:after{content:''}.ss-skype:before,.ss-skype.right:after{content:''}.ss-rdio:before,.ss-rdio.right:after{content:''}.ss-spotify:before,.ss-spotify.right:after{content:''}.ss-lastfm:before,.ss-lastfm.right:after{content:''}.ss-mail:before,.ss-mail.right:after{content:'✉'}.ss-like:before,.ss-like.right:after{content:'👍'}.ss-rss:before,.ss-rss.right:after{content:''}
